a.navbar-brand {
	white-space: normal;
	text-align: center;
	word-break: break-all;
	font-weight: bold;
	color: white;
	font-size: 24px;
	top: -0.25rem;
	position: relative;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


nav button {
	overflow: hidden;
}

header>nav>div.container-fluid>div {
	width: 100%;
}
