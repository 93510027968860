


#layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#layout > header {
    flex-basis: min-content;
    overflow: none;
}

#layout > div {
    overflow: auto;
    flex-basis: max-content;
}