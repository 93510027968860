html, body, #root, #root>div, .container-fluid {
  height: 100%
}

body {
	background:  url( https://kerst.duthler.net/api/static/images/tree.jpg ) center top no-repeat;
}


.container-fluid 
{
}

.user-column {
	border-left: 1px solid silver;
	border-right: 1px solid silver;
}

.north_pole_sign 
{
	width: 103px;
	height: 175px;
	position: fixed;
	bottom: 0;
	right: 0;
	background: url( './north_pole_sign.png' ) center bottom no-repeat;
	background-size: cover;
	z-index: -1;
}
